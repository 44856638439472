import { styled } from '@mui/system';
import { Divider } from '@mui/material';


export const FDivider = styled(Divider)({
    // borderColor: theme.palette.divider, #d7d5cd
    borderBottomWidth: '0.1rem'
});


